import Mobius from './mobius';
import configPrograms from './config/programs.config';
import { Constructor } from './ts/mobius.d';
import { Request, Response } from './ts/programs.d';

export class Programs extends Mobius {
  /**
   * request
   *
   * @param {Request} request api request containing context, criteria, & etc.
   */
  public request: Request;

  /**
   * response
   *
   * @param {Response} response response object to return from api.
   */
  public response: Response[];

  /**
   * filter
   *
   * Method used to filter for products based on specified property
   * search criteria.
   *
   * @param {'school' | 'program'} property property to search in.
   * @param {Record<string, unknown>} search criteria to search in specified property.
   * @param {string} property child property to search in.
   * @return {Response[]} filtered products based on specified property search criteria.
   */
  private filter(
    property: 'school' | 'program',
    search: Record<string, unknown>,
    childProperty: string = null
  ): Response[] {
    const results: Response[] = [];

    // return, missing arguments
    if (
      !property ||
      typeof search !== 'object' ||
      !search ||
      !this.response ||
      !this.response.length
    ) {
      return results;
    }

    // search
    const key = Object.keys(search)[0];
    const value = Object.values(search)[0];

    // results
    this.response.forEach((item) => {
      if (childProperty) {
        if (item.product[property][childProperty][key] === value) {
          results.push(item);
        }
      } else if (item.product[property][key] === value) {
        results.push(item);
      }
    });

    // return
    return results;
  }

  /**
   * program
   *
   * Method used to filter for products based on program
   * search criteria.
   *
   * @param {Record<string, unknown>} search criteria to search in programs.
   * @return {Response[]} filtered products based on program search criteria.
   */
  program(search: Record<string, unknown>): Response[] {
    return typeof search === 'object' && search
      ? this.filter('program', search)
      : [];
  }

  /**
   * school
   *
   * Method used to filter for products based on school
   * search criteria.
   *
   * @param {Record<string, unknown>} search criteria to search in schools.
   * @return {Response[]} filtered products based on school search criteria.
   */
  school(search: Record<string, unknown>): Response[] {
    return typeof search === 'object' && search
      ? this.filter('school', search)
      : [];
  }

  /**
   * degree
   *
   * Method used to filter for products based on program degree
   * search criteria.
   *
   * @param {Record<string, unknown>} search criteria to search in schools.
   * @return {Response[]} filtered products based on school search criteria.
   */
  degree(search: Record<string, unknown>): Response[] {
    return typeof search === 'object' && search
      ? this.filter('program', search, 'degree')
      : [];
  }

  /**
   * category
   *
   * Method used to filter for products based on program category
   * search criteria.
   *
   * @param {Record<string, unknown>} search criteria to search in schools.
   * @return {Response[]} filtered products based on school search criteria.
   */
  category(search: Record<string, unknown>): Response[] {
    return typeof search === 'object' && search
      ? this.filter('program', search, 'category')
      : [];
  }

  /**
   * subject
   *
   * Method used to filter for products based on program subject
   * search criteria.
   *
   * @param {Record<string, unknown>} search criteria to search in schools.
   * @return {Response[]} filtered products based on school search criteria.
   */
  subject(search: Record<string, unknown>): Response[] {
    return typeof search === 'object' && search
      ? this.filter('program', search, 'subject')
      : [];
  }

  /**
   * getContentList
   *
   * Method used to find inner content lists from descriptions.
   *
   * @param {string} content content from school or program description/snippet.
   * @param {string} className explicit class that gets added to list item
   * @return {string} content plus list markup.
   */
  // eslint-disable-next-line class-methods-use-this
  getContentWithList(content: string, className = 'checkmark'): string {
    return content.replace(
      /(- ([A-Z1-9](.*)\n?))/g,
      `<span class="${className}">$2</span>`
    );
  }
}

// asynchronous factory function
export default async (props?: Constructor): Promise<Programs> => {
  const programs = new Programs({
    config: configPrograms,
    ...props,
  });
  await programs.initialize();
  return programs;
};
