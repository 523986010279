import { html, TemplateResult } from 'lit-element';

export default (props): TemplateResult => {
  return html`
    ${
      props.hideDisclosure
        ? ''
        : html`
          <sonic-tooltip class="mb-4 block text-xs text-center" label="${
            window.HE ? 'Learn More About Our Partners' : 'Advertisement'
          }">
            <p>
              ${window.location.hostname}
              is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.
            </p>
          </sonic-tooltip>
          `
    }
    <slot></slot>
    <div class="flex flex-col">
      ${props.mobius.response.map(({ product, metadata }, index) => {
        const { program, school, destination } = product;
        const position = index + 1;
        const snippet =
          program.snippet ||
          program.description ||
          school.snippet ||
          school.description;
        return html`
          <div
            class="rounded border flex shadow flex-col md:flex-row md:items-center mb-4 bg-white ${
              props.clickableCards ? 'cursor-pointer' : ''
            }"
            data-editorial-listing=${program.id}
            @click=${
              props.clickableCards
                ? (e) => props.handleClick(e, product, metadata, position)
                : undefined
            }
          >
            <div class="logo-wrapper p-4 md:p-6 flex-shrink-0 md:w-52 md:flex md:items-center md:justify-center">
              <img
                class="logo"
                src="https://simple-storage-server.highereducation.com/${
                  school.slug
                }.png?w=160"
                alt="${school.name}"
                width="160"
                height="72"
                loading="lazy"
              />
            </div>
            <div class="relative px-4 md:px-0 md:py-6">
              <div class="border-t md:border-t-0 md:border-l pt-4 pb-9 md:py-0 md:px-6">
                <span class="program text-xs font-semibold uppercase leading-4 block">
                  ${program.name}
                </span>
                <a
                  @click=${(e) =>
                    props.handleClick(e, product, metadata, position)}
                  href="${destination.url}"
                  class="school font-bold block text-base md:text-xl mb-3"
                  data-test="school-link"
                  rel="sponsored">
                    ${school.name}
                  </a>
                <p class="text-sm mb-0">${snippet}</p>
              </div>
            </div>
            <div class="flex flex-col items-center justify-center px-4 pb-6 md:pl-0 md:pb-0 md:pr-6">

              <button
                @click=${(e) =>
                  props.handleClick(e, product, metadata, position)}
                class="el-button card-button button block w-full py-3 leading-4 text-center text-sm rounded-full"
                data-test="visit-school-button">
                  ${props.button}
              </button>
            </div>
          </div>
        `;
      })}
    </div>
    ${
      props.hideMoreButton
        ? ''
        : html`
        <button
          class="el-button view-more-button button block mx-auto mt-2 mb-4 w-56 py-3 leading-4 text-center text-sm rounded-full"
          data-test="view-more-button"
          @click=${() => props.handleMoreClick()}
        >
          ${props.morebutton}
        </button>
      `
    }
  `;
};
