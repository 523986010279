import { html, TemplateResult } from 'lit-element';

export default (props): TemplateResult => {
  return html`
    ${
      props.hideDisclosure
        ? ''
        : html`
          <sonic-tooltip class="mb-4 text-xs text-center" label="${
            window.HE ? 'Learn More About Our Partners' : 'Advertisement'
          }">
            <p>
              ${window.location.hostname}
              is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.
            </p>
          </sonic-tooltip>
          `
    }
    <slot></slot>
    <div class="flex flex-col">
      ${props.mobius.response.map(({ product, metadata }, index) => {
        const { program, school, destination } = product;
        const position = index + 1;
        return html`
          <div
            class="card border rounded flex flex-row justify-between mb-4 shadow-lg ${
              props.clickableCards ? 'cursor-pointer' : ''
            }"
            data-editorial-listing=${program.id}
            @click=${
              props.clickableCards
                ? (e) => props.handleClick(e, product, metadata, position)
                : undefined
            }
          >
            <div class="md:border-b-0 items-center py-4">
              <div class="flex flex-row">
                <img
                  class="hidden md:block mx-10 h-14"
                  src="https://simple-storage-server.highereducation.com/${
                    school.slug
                  }.png?w=57&h=50"
                  alt="${school.name}"
                  width="57"
                  height="50"
                  loading="lazy"
                />
                <div class="flex flex-col px-4">
                  <a
                    @click=${(e) =>
                      props.handleClick(e, product, metadata, position)}
                    href="${destination.url}"
                    class="school font-bold block text-base md:text-xl mb-1 leading-normal"
                    data-test="school-link"
                    rel="sponsored">
                      ${school.name}
                  </a>
                  <p class="location text-xs font-bold uppercase leading-relaxed">${
                    school.city && school.state
                      ? `${school.city}, ${school.state} / `
                      : ''
                  }Online</p>
                </div>
              </div>
            </div>
            <div class="flex justify-center items-center py-1">
              <div class="flex flex-col px-3 border-l md:px-6 md:py-1 ">
                  <img
                  class="block m-auto md:hidden "
                  src="https://simple-storage-server.highereducation.com/${
                    school.slug
                  }.png?w=41&h=36"
                  alt="${school.name}"
                  width="41"
                  height="36"
                  loading="lazy"
                />
                <button
                  @click=${(e) =>
                    props.handleClick(e, product, metadata, position)}
                  class="el-button button border rounded-sm block py-3 leading-4 my-1 text-center text-sm w-28 md:w-60 lg:w-72"
                  data-test="visit-school-button">
                  ${props.button}
                </button>
              </div>
            </div>
          </div>
        `;
      })}
    </div>
  `;
};
