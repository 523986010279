import { TemplateResult } from 'lit-element';
import * as Layouts from './layouts';

export default (props): TemplateResult => {
  switch (props.layout) {
    case 'fallback': {
      return Layouts.fallback();
    }
    case 'grid': {
      return Layouts.grid(props);
    }
    case 'horizontal': {
      return Layouts.horizontal(props);
    }
    case 'flex': {
      return Layouts.flex(props);
    }
    case 'simple': {
      return Layouts.simple(props);
    }
    case 'school-banner': {
      return Layouts.schoolBanner(props);
    }
    case 'left-logos': {
      return Layouts.leftLogos(props);
    }
    case 'stacked':
    default: {
      return Layouts.stacked(props);
    }
  }
};
