// eslint-disable-next-line max-classes-per-file
import { LitElement, TemplateResult, css, unsafeCSS } from 'lit-element';
import { customElement, property } from 'lit-element/lib/decorators';
import { CSSResultGroup } from '../../ts/types';
import { Criteria, Metadata, Product } from '../../utils/mobius/ts/programs.d';
import { LayoutType } from './interfaces.d';
import categoryMap from '../../utils/mobius/maps/category.json';
import degreeMap from '../../utils/mobius/maps/degree.json';
import getAliasParams from '../../utils/tagular/getAliasParams';
import getPresets from '../../utils/getPresets';
import getTrafficSource from '../../utils/getTrafficSource';
import getUrlQuery from '../../utils/mobius/lib/getUrlQuery';
import mobiusPrograms, { Programs } from '../../utils/mobius/programs';
import setCustomAttribute from '../../utils/newRelic/setCustomAttribute';
import styles from './styles.scss';
import subjectMap from '../../utils/mobius/maps/subject.json';
import tagularEvent from '../../utils/tagular/event';
import template from './template';
import uuid from '../../utils/uuid';
import setMarkMetric from '../../utils/performance/setMarkMetric';
import setResourceMetric from '../../utils/performance/setResourceMetric';
class WebComponent extends LitElement {
  /**
   * mobius
   *
   * @param {string} mobius mobius api response of programs.
   */
  mobius: Programs;

  /**
   * token
   *
   * @param {string} token the mobius token
   */
  @property({ type: String })
  token: string;

  /**
   * viewCorrelationid
   *
   * @param {string} uuid
   */
  @property({ type: String })
  viewCorrelationId;

  /**
   * formatSubtype
   *
   * @param {string} formatSubtype
   */
  @property({ type: String })
  formatSubtype = 'sonic-editorial-listings';

  /**
   * formatType
   *
   * @param {string} formatType
   */
  @property({ type: String })
  formatType = 'ad';

  /**
   * degree
   *
   * @param {string} degree selected degree
   */
  @property({ type: String, reflect: true })
  degree: string;

  /**
   * Default Degree
   *
   * @param {string} _degree degree default
   */
  @property({ type: String })
  _degree: string;

  /**
   * category
   *
   * @param {string} category selected category
   */
  @property({ type: String, reflect: true })
  category: string;

  /**
   * Default Category
   *
   * @param {string} _category category default
   */
  @property({ type: String })
  _category: string;

  /**
   * subject
   *
   * @param {string} subject selected subject.
   */
  @property({ type: String, reflect: true })
  subject: string;

  /**
   * Default Subject
   *
   * @param {string} _subject subject default
   */
  @property({ type: String })
  _subject: string;

  /**
   * customDimensions
   *
   * @param {string} customDimensions additional tracking parameters
   */
  @property({ type: String })
  customDimensions: string;

  /**
   * customDimensionsArray
   *
   * @param {string} customDimensionsArray additional tracking parameters as an array
   */
  @property({ type: Array })
  customDimensionsArray = [];

  /**
   * program
   *
   * @param {number[]} program array of programs.
   */
  @property({ type: Array })
  program: number[];

  /**
   * layout
   *
   * @param {LayoutType} layout different layout types.
   */
  @property({ type: String, reflect: true })
  layout: LayoutType = 'stacked';

  /**
   * media
   *
   * @param {string} media sets value for context.media in mobius request
   */
  @property({ type: String })
  media = 'organic';

  /**
   * schoolId
   *
   * @param {number[]} schoolId sets value for criteria.schoolId in mobius request
   */
  @property({ type: Array })
  schoolId: number[];

  /**
   * eventLocation
   *
   * @param {string} eventLocation location for tagular events.
   */
  @property({ type: String })
  eventLocation: string = this.layout;

  /**
   * disclosure
   *
   * @param {boolean} disclosure toggle ad disclosure.
   */
  @property({ type: Boolean })
  hideDisclosure;

  /**
   * related programs
   *
   * @param {boolean} includeRelatedPrograms toggle mobius includeRelatedPrograms.
   */
  @property({ type: String })
  includeRelatedPrograms;

  /**
   * distinct schools
   *
   * @param {boolean} distinctSchoolsOnly toggle mobius distinctSchoolsOnly.
   */
  @property({ type: String })
  distinctSchoolsOnly;

  /**
   * on campus programs
   *
   * @param {boolean} onCampusProgramsOnly toggle mobius onCampusProgramsOnly.
   */
  @property({ type: String })
  onCampusProgramsOnly;

  /**
   * online programs
   *
   * @param {boolean} onlineProgramsOnly toggle mobius onlineProgramsOnly.
   */
  @property({ type: String })
  onlineProgramsOnly;

  /**
   * limit
   *
   * @param {number} limit amount of editorial listings to return.
   */
  @property({ type: Number, reflect: true })
  limit = 4;

  /**
   * offset
   *
   * @param {number} offset editorial listings.
   */
  @property({ type: Number, reflect: true })
  offset = 0;

  /**
   * button
   *
   * @param {string} button editorial listing button label.
   */
  @property({ type: String })
  button = 'Visit Site';

  /**
   * morebutton
   *
   * @param {string} morebutton view more button label.
   */
  @property({ type: String })
  morebutton = 'View More Programs';

  /**
   * hideMoreButton
   *
   * @param {boolean} hideMoreButton toggle view more button.
   */
  @property({ type: Boolean })
  hideMoreButton;

  /**
   * extclid
   *
   * @param {string} extclid when present, adds this property to the query string of the URL (and to customDimensionsArray for eventing)
   */
  @property({ type: String })
  extclid = '';

  /**
   * url
   *
   * @param {string} url study match destination url.
   */
  @property({ type: String })
  url = 'https://schools.collegedegrees.com/app/experience';

  /**
   * publisher
   *
   * @param {string} publisher the publisher slug
   */
  @property({ type: String })
  publisher: string;

  /**
   * source
   *
   * @param {string} source get source of traffic.
   */
  @property({ type: String })
  source: string = getTrafficSource(window.location.href);

  /**
   * clickableCards
   *
   * @param {boolean} clickableCards make each EL cards clickable.
   */
  @property({ type: Boolean })
  clickableCards = true;

  /**
   * setAliasParams
   *
   * @param {boolean} setAliasParams toggle tagular alias query params
   */
  @property({ type: Boolean })
  setAliasParams = true;

  /**
   * showProgramTypeBadge
   *
   * @param {boolean} showProgramTypeBadge show badges for online/on campus programs
   */
  @property({ type: String })
  showProgramTypeBadge;

  /*
   * styles
   * lit-element property declaration
   */
  static get styles(): CSSResultGroup {
    return [css`${unsafeCSS(styles)}`];
  }

  /**
   * monetizationChannel
   *
   * @param {string}
   */
  @property({ type: String })
  monetizationChannel = 'editorial-links';

  /**
   * experience
   *
   * @param {string}
   */
  @property({ type: String })
  experience = 'editorial-links';

  /*
   * render
   * lit-element lifecycle method
   */
  render(): TemplateResult {
    if (!this.mobius) {
      return null;
    }

    // metric: html-rendered mark
    setMarkMetric({
      namespace: this.tagName,
      mark: 'html-rendered',
      newRelic: true,
      measures: [
        {
          startMark: 'api-response',
        },
        {
          startMark: 'dom-connected',
          newRelic: true,
        },
      ],
    });

    return template(this);
  }

  /*
   * connectedCallback
   *
   * fires each time a custom element is appended
   * into a document-connected element.
   */
  async connectedCallback(): Promise<void> {
    // metric: component resource
    setResourceMetric({
      namespace: this.tagName,
      resource: 'sonic-qdf.js',
      measures: [
        {
          label: 'startTime',
          data: 'fetchStart',
        },
        {
          label: 'fetchStart -> responseEnd',
          data: 'duration',
        },
        {
          label: 'responseEnd',
          data: 'responseEnd',
        },
      ],
    });
    this.viewCorrelationId = uuid();

    // metric: dom-connected mark
    setMarkMetric({
      namespace: this.tagName,
      mark: 'dom-connected',
      newRelic: true,
    });

    super.connectedCallback();

    // presets
    getPresets(this);
    if (!this.publisher) {
      this.publisher = window?.HE?.publisher?.name || '';
    }
    // criteria
    const criteria: Criteria = this.getCriteria();

    // metric: api-request mark
    setMarkMetric({
      namespace: this.tagName,
      mark: 'api-request',
      measures: [
        {
          startMark: 'dom-connected',
        },
      ],
    });

    // mobius
    this.mobius = await mobiusPrograms({
      context: {
        experience: 'editorial-links',
        media: this.media,
      },
      criteria,
      token: this.token,
      trackingContext: {
        formatSubtype: this.formatSubtype,
        formatType:
          this.layout.toString() === 'simple' ? 'rankings ad' : this.formatType,
      },
    });
    Object.assign(this.mobius, {
      response: this.mobius.response.map((res) => {
        return {
          ...res,
          product: { ...res.product, viewCorrelationId: uuid() },
        };
      }),
    });

    // metric: api-request mark
    setMarkMetric({
      namespace: this.tagName,
      mark: 'api-response',
      measures: [
        {
          startMark: 'api-request',
          newRelic: true,
        },
      ],
    });

    // fallback layout, if no response
    if (!this.mobius.response || !this.mobius.response.length) {
      this.layout = 'fallback';
      setCustomAttribute('NoElMobiusResponse', 'true');
    }

    // stacked layout if only one result
    if (
      this.mobius.response.length === 1 &&
      this.layout.toString() !== 'simple'
    ) {
      this.layout = 'stacked';
    }

    // new relic: results empty
    if (
      this.mobius.response &&
      Array.isArray(this.mobius.response) &&
      this.mobius.response.length === 0
    ) {
      setCustomAttribute('ElMobiusResponseEmpty', 'true');
    }

    // additional tracking in a parameter that formatted with a key and value like so:
    // customDimensions="isonline:true,isoncampus:false"
    if (this.customDimensions) {
      const dimensionArray = this.customDimensions.split(',');

      const customDimensions = dimensionArray.reduce((previous, current) => {
        const currentItem = current.split(':');

        const currentObject = {
          key: currentItem[0].trim(),
          value: currentItem[1].trim(),
        };

        previous.push(currentObject);
        return previous;
      }, []);
      this.customDimensionsArray = customDimensions;
    }

    // Adding extclid (External Click ID - to be used by affiliates) to the customDimensions array
    if (this.extclid) {
      this.customDimensionsArray.push({
        key: 'extclid',
        value: this.extclid,
      });
    }

    // manual request update
    await this.requestUpdate();

    // tagular product(s) loaded
    this.tagularProductLoaded();

    // handle product viewed events
    this.handleProductViewed();

    this.handleViewMoreElementsViewed();
  }

  getIsUserRequested(program) {
    if (!Array.isArray(this.mobius.response) || !program.id) {
      return '';
    }

    try {
      const mobiusIndex = this.mobius.response.findIndex(
        (item) => item.product.program.id === program.id
      );
      const isUserRequested = !this.mobius.response[mobiusIndex].related;
      return isUserRequested.toString();
    } catch (error) {
      return '';
    }
  }

  /**
   * getCriteria
   *
   * get local attribute context then window contextual defaults.
   */
  getCriteria(): Criteria {
    const criteria: Criteria = {
      limit: this.limit,
      offset: this.offset,
      schoolId: this.schoolId,
    };

    // defaults, local attributes > contextual defaults
    if (!this.degree && !this.category && !this.subject) {
      this.degree = this._degree;
      this.category = this._category;
      this.subject = this._subject;
    }

    // degree
    if (degreeMap?.[this.degree]) {
      criteria.degreeId = degreeMap[this.degree];
    }

    // category
    if (categoryMap?.[this.category]) {
      criteria.categoryId = categoryMap[this.category];
    }

    // subject
    if (subjectMap?.[this.subject]) {
      criteria.subjectId = subjectMap[this.subject];
    }

    // program
    if (this.program) {
      if (typeof this.program === 'number') {
        this.program = [this.program];
      }
      criteria.programId = this.program;
    }

    // related programs, ignored by mobius if degreeid or subjectid are not included
    if (this.includeRelatedPrograms === 'false') {
      criteria.includeRelatedPrograms = false;
    }

    // distinct schools only
    if (this.distinctSchoolsOnly === 'false') {
      criteria.distinctSchoolsOnly = false;
    }

    if (this.onCampusProgramsOnly === 'true') {
      criteria.onCampusProgramsOnly = true;
    } else if (this.onlineProgramsOnly === 'true') {
      criteria.onlineProgramsOnly = true;
    }
    // return
    return criteria;
  }

  /**
   * handleClick
   *
   * Handle product click functionality. This includes sending
   * a tagular product clicked event, then opening outbound url.
   */
  async handleClick(
    e: MouseEvent,
    product: Product,
    metadata: Metadata,
    position: number
  ): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    // update tracking context
    await this.mobius.updateTrackingContext();
    // correlation id genreated on click
    const correlationId = uuid();

    // outbound url
    const outboundUrl = getUrlQuery(product.destination.url, {
      correlationId,
      helid: product.destination.helid,
      idToken: this.mobius.token,
      position,
      source: this.source,
      trackingContext: this.mobius.trackingContext,
      monetizationChannel: this.monetizationChannel,
      experience: this.experience,
    });
    const { viewCorrelationId } = product;

    // tagular event: ProductClicked
    // redventures.ecommerce.v1.ProductClicked
    // https://app.make.rvapps.io/schemas/sch_1KdMzUut6pgFqvEatLszkBGhCob
    tagularEvent('ProductClicked', {
      correlationId,
      outboundUrl,
      product: {
        brand: product.program.schoolSlug,
        category: product.program.category.slug || 'general',
        formatSubtype: this.formatSubtype,
        formatType:
          this.layout.toString() === 'simple' ? 'rankings ad' : this.formatType,
        location: this.eventLocation,
        name: product.program.subject.slug || 'general',
        position,
        productId: product.program.id ? product.program.id.toString() : '',
        sku: metadata.capId ? metadata.capId.toString() : '',
        variant: product.program.degree.slug || 'general',
      },
      viewCorrelationId,
      customDimensions: [
        ...this.customDimensionsArray,
        {
          key: 'isUserRequested',
          value: this.getIsUserRequested(product.program),
        },
      ],
      listId: this.mobius.id,
    });

    // link
    window.open(outboundUrl, '_blank', 'noopener');
  }

  /**
   * handleMoreClick
   *
   * Handle the view more product click functionality. This includes sending
   * a tagular element clicked event, then opening outbound url.
   */
  handleMoreClick(): void {
    // correlation id genreated on click
    const correlationId = uuid();

    // outbound url
    const outboundUrl = getUrlQuery(this.url, {
      publisher: this.publisher,
      url: window.location.href,
      degree: this.degree || '',
      category: this.category || '',
      subject: this.subject || '',
      extclid: this.extclid,
      correlationId,
      trafficSource: this.source,
      ...(this.setAliasParams ? getAliasParams() : {}),
    });

    // tagular event: ElementClicked
    // redventures.usertracking.v3.ElementClicked
    // https://app.make.rvapps.io/schemas/sch_1KdMzeZCVB3cFlvsaIXpcvuhBno
    tagularEvent('ElementClicked', {
      correlationId,
      outboundUrl,
      webElement: {
        position: 'Below Editorial Listings',
        location: this.eventLocation,
        elementType: 'Link',
        text: this.morebutton,
      },
      customDimensions: this.customDimensionsArray,
      viewCorrelationId: this.viewCorrelationId,
    });

    // link
    window.open(outboundUrl, '_blank', 'noopener');
  }

  /**
   * tagularProductLoaded
   *
   * tagular event: ProductLoaded
   * redventures.ecommerce.v1.ProductLoaded
   * https://app.make.rvapps.io/schemas/sch_1MyCkGNK8h7RRi2qEAhT5DFEOt3
   * trigger ProductLoaded on each program loaded into the
   * editorial listing.
   */
  tagularProductLoaded(): void {
    this.mobius.response.forEach(
      ({ product: { program, viewCorrelationId }, metadata }, index) => {
        tagularEvent('ProductLoaded', {
          product: {
            brand: program.schoolSlug,
            category: program.category.slug || 'general',
            formatSubtype: this.formatSubtype,
            formatType:
              this.layout.toString() === 'simple'
                ? 'rankings ad'
                : this.formatType,
            location: this.eventLocation,
            name: program.subject.slug || 'general',
            position: index + 1,
            productId: program.id ? program.id.toString() : '',
            sku: metadata.capId ? metadata.capId.toString() : '',
            variant: program.degree.slug || 'general',
          },
          viewCorrelationId,
          customDimensions: [
            ...this.customDimensionsArray,
            {
              key: 'isUserRequested',
              value: this.getIsUserRequested(program),
            },
          ],
          listId: this.mobius.id,
        });
      }
    );
  }

  /**
   * handleProductViewed
   *
   * Handle the product viewed functionality using the native Intersection
   * Observer API.
   */
  handleProductViewed(): void {
    // editorial listings elements
    const editorialListings = Array.from(
      this.shadowRoot.querySelectorAll('[data-editorial-listing]')
    );

    // attach intersection observer to each editorial listing
    editorialListings.forEach((editorialListing: HTMLElement, index) => {
      const programId = Number(editorialListing.dataset.editorialListing);
      const observer: IntersectionObserver = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            // tagular product viewed
            this.tagularProductViewed(programId, index);

            // disconnect observer
            observer.disconnect();
          }
        },
        {
          threshold: 0.5,
        }
      );

      // connect observer
      observer.observe(editorialListing);
    });
  }
  handleViewMoreElementsViewed(): void {
    // view more button element(s)
    const viewMoreButton = Array.from(
      this.shadowRoot.querySelectorAll('[data-test="view-more-button"]')
    );

    // attach intersection observer to each button(s)
    viewMoreButton.forEach((button: HTMLElement) => {
      const observer: IntersectionObserver = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            // tagular Element viewed
            this.tagularElementViewed();

            // disconnect observer
            observer.disconnect();
          }
        },
        {
          threshold: 0.5,
        }
      );

      // connect observer
      observer.observe(button);
    });
  }

  /**
   * tagularProductViewed
   *
   * tagular event: ProductViewed
   * redventures.ecommerce.v1.ProductViewed
   * https://app.make.rvapps.io/schemas/sch_1KdMzjSmvkUNkkjGM5VDHhjWIzQ
   * trigger ProductViewed on each program loaded into the
   * editorial listing.
   */
  tagularProductViewed(programId: number, index: number): void {
    const {
      product: { program, viewCorrelationId },
      metadata,
    } = this.mobius.program({ id: programId })[0];
    // tagular event
    tagularEvent('ProductViewed', {
      product: {
        brand: program.schoolSlug,
        category: program.category.slug || 'general',
        formatSubtype: this.formatSubtype,
        formatType:
          this.layout.toString() === 'simple' ? 'rankings ad' : this.formatType,
        location: this.eventLocation,
        name: program.subject.slug || 'general',
        position: index + 1,
        productId: program.id ? program.id.toString() : '',
        sku: metadata.capId ? metadata.capId.toString() : '',
        variant: program.degree.slug || 'general',
      },
      viewCorrelationId,
      customDimensions: [
        ...this.customDimensionsArray,
        {
          key: 'isUserRequested',
          value: this.getIsUserRequested(program),
        },
      ],
      listId: this.mobius.id,
    });
  }

  tagularElementViewed(): void {
    tagularEvent('ElementViewed', {
      viewCorrelationId: this.viewCorrelationId,
      webElement: {
        position: 'Below Editorial Listings',
        location: this.eventLocation,
        elementType: 'link',
        htmlId: this.layout,
        text: this.morebutton,
      },
    });
  }
}

// Publisher EL
@customElement('sonic-editorial-listings')
export class PublisherEditorialListings extends WebComponent {}

// Affiliate EL
@customElement('he-editorial-listings')
export class AffiliateEditorialListings extends WebComponent {}
