import { html, TemplateResult } from 'lit-element';

export default (props): TemplateResult => {
  return html`
    ${
      props.hideDisclosure
        ? ''
        : html`
          <sonic-tooltip class="mb-4 block text-xs text-center" label="${
            window.HE ? 'Learn More About Our Partners' : 'Advertisement'
          }">
            <p>
              ${window.location.hostname}
              is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.
            </p>
          </sonic-tooltip>
          `
    }
    <slot></slot>
    <div class="flex flex-col">
      ${props.mobius.response.map(({ product, metadata }, index) => {
        const { program, school, destination } = product;
        const position = index + 1;
        const snippet =
          program.snippet ||
          program.description ||
          school.snippet ||
          school.description;
        const programBadge = program.online
          ? html`
            <div class="el-badge online">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 .333a1 1 0 1 0 0 2v-2ZM9.667 10a1 1 0 1 0 2 0h-2ZM2 4.333a1 1 0 1 0 0 2v-2ZM5.667 10a1 1 0 1 0 2 0h-2Zm-4 0c0-.184.149-.333.333-.333v2c.92 0 1.667-.746 1.667-1.667h-2ZM2 9.667c.184 0 .333.149.333.333h-2c0 .92.746 1.667 1.667 1.667v-2Zm.333.333c0 .184-.149.333-.333.333v-2C1.08 8.333.333 9.08.333 10h2ZM2 10.333A.333.333 0 0 1 1.667 10h2c0-.92-.747-1.667-1.667-1.667v2Zm0-8A7.667 7.667 0 0 1 9.667 10h2C11.667 4.661 7.339.333 2 .333v2Zm0 4A3.667 3.667 0 0 1 5.667 10h2A5.667 5.667 0 0 0 2 4.333v2Z" fill="#0D485D"/></svg>
              <p class="leading-none text-xs font-semibold">Online</p>
            </div>
          `
          : html`
          <div class="el-badge campus">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.667 5.667v-.75a.75.75 0 0 0-.75.75h.75Zm10.666 0h.75a.75.75 0 0 0-.75-.75v.75Zm0 7.333v.75a.75.75 0 0 0 .75-.75h-.75ZM1.667 13h-.75c0 .414.335.75.75.75V13Zm3.416-4.667a.75.75 0 1 0-1.5 0h1.5Zm-1.5 2a.75.75 0 0 0 1.5 0h-1.5Zm4.167-2a.75.75 0 0 0-1.5 0h1.5Zm-1.5 2a.75.75 0 0 0 1.5 0h-1.5Zm4.167-2a.75.75 0 0 0-1.5 0h1.5Zm-1.5 2a.75.75 0 0 0 1.5 0h-1.5ZM1 12.25a.75.75 0 0 0 0 1.5v-1.5Zm12 1.5a.75.75 0 0 0 0-1.5v1.5ZM1 4.917a.75.75 0 1 0 0 1.5v-1.5Zm12 1.5a.75.75 0 0 0 0-1.5v1.5ZM.695 2.98a.75.75 0 0 0 .61 1.371l-.61-1.37ZM7 1l.305-.685a.75.75 0 0 0-.61 0L7 1Zm5.695 3.352a.75.75 0 0 0 .61-1.37l-.61 1.37ZM1.667 6.417h10.666v-1.5H1.667v1.5Zm9.916-.75V13h1.5V5.667h-1.5Zm.75 6.583H1.667v1.5h10.666v-1.5ZM2.417 13V5.667h-1.5V13h1.5Zm1.166-4.667v2h1.5v-2h-1.5Zm2.667 0v2h1.5v-2h-1.5Zm2.667 0v2h1.5v-2h-1.5ZM1 13.75h12v-1.5H1v1.5Zm0-7.333h12v-1.5H1v1.5Zm.305-2.065 6-2.667-.61-1.37-6 2.666.61 1.371Zm5.39-2.667 6 2.667.61-1.37-6-2.667-.61 1.37Z" fill="#550765"/></svg>
            <p class="leading-none text-xs font-semibold">On-Campus</p>
          </div>
        `;
        return html`
          <div
            class="rounded border flex flex-col md:flex-row mb-4 bg-white ${
              props.clickableCards ? 'cursor-pointer' : ''
            } ${props.showProgramTypeBadge ? 'has-badge' : ''}"
            data-editorial-listing=${program.id}
            @click=${
              props.clickableCards
                ? (e) => props.handleClick(e, product, metadata, position)
                : undefined
            }
          >
            <div class="relative md:border-r flex-1">
              <div class="p-6 border-b relative">
                <span class="program text-xs font-semibold uppercase leading-4 block">
                  ${program.name}
                </span>
                <a
                  @click=${(e) =>
                    props.handleClick(e, product, metadata, position)}
                  href="${destination.url}"
                  class="school font-bold block text-lg"
                  data-test="school-link"
                  rel="sponsored">
                    ${school.name}
                  </a>
                  ${props.showProgramTypeBadge ? programBadge : ''}
              </div>
              <div class="p-6">
                <p class="text-sm mb-3">${snippet}</p>
                <button
                  @click=${(e) =>
                    props.handleClick(e, product, metadata, position)}
                  href="${destination.url}"
                  class="link text-sm font-bold"
                  data-test="learn-more-button">
                    Learn More
                </button>
              </div>
            </div>
            <div class="pb-6 px-6 flex flex-col flex-shrink-0 items-center justify-center md:w-72">
              <img
                class="mb-6"
                src="https://simple-storage-server.highereducation.com/${
                  school.slug
                }.png?w=160&h=72"
                alt="${school.name}"
                width="160"
                height="72"
                loading="lazy"
              />
              <button
                @click=${(e) =>
                  props.handleClick(e, product, metadata, position)}
                class="el-button button block w-full py-3 leading-4 text-center text-sm rounded-full"
                data-test="visit-school-button">
                  ${props.button}
              </button>
            </div>
          </div>
        `;
      })}
    </div>
    ${
      props.hideMoreButton
        ? ''
        : html`
        <button
          class="el-button el-view-more button block mx-auto mt-2 mb-4 w-56 py-3 leading-4 text-center text-sm rounded-full"
          data-test="view-more-button"
          @click=${() => props.handleMoreClick()}
        >
          ${props.morebutton}
        </button>
      `
    }
  `;
};
