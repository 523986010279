import { html, TemplateResult } from 'lit-element';

export default (props): TemplateResult => {
  return html`
    ${
      props.hideDisclosure
        ? ''
        : html`
          <sonic-tooltip class="mb-4 block text-xs text-center" label="${
            window.HE ? 'Learn More About Our Partners' : 'Advertisement'
          }">
            <p>
              ${window.location.hostname}
              is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.
            </p>
          </sonic-tooltip>
          `
    }
    <slot></slot>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      ${props.mobius.response.map(({ product, metadata }, index) => {
        const { program, school, destination } = product;
        const position = index + 1;
        const snippet =
          program.snippet ||
          program.description ||
          school.snippet ||
          school.description;
        return html`
          <div
            class="rounded border flex flex-col md:flex-row bg-white ${
              props.clickableCards ? 'cursor-pointer' : ''
            }"
            data-editorial-listing=${program.id}
            @click=${
              props.clickableCards
                ? (e) => props.handleClick(e, product, metadata, position)
                : undefined
            }
          >
            <div class="relative md:border-r">
              <div class="p-4 border-b">
                <span class="program text-xs font-semibold uppercase leading-4 block">
                  ${program.name}
                </span>
                <a
                  @click=${(e) =>
                    props.handleClick(e, product, metadata, position)}
                  href="${destination.url}"
                  class="school font-bold block text-lg"
                  data-test="school-link"
                  rel="sponsored">
                    ${school.name}
                  </a>
              </div>
              <div class="p-4">
                <p class="text-xs mb-3">${snippet}</p>
                <button
                  @click=${(e) =>
                    props.handleClick(e, product, metadata, position)}
                  href="${destination.url}"
                  class="link text-sm font-bold"
                  data-test="learn-more-button">
                    Learn More
                </button>
              </div>
            </div>
            <div class="pb-4 px-4 flex flex-col flex-shrink-0 items-center justify-center md:w-40">
              <img
                class="mb-6"
                src="https://simple-storage-server.highereducation.com/${
                  school.slug
                }.png?w=160&h=72"
                alt="${school.name}"
                width="160"
                height="72"
                loading="lazy"
              />
              <button
                @click=${(e) =>
                  props.handleClick(e, product, metadata, position)}
                class="el-button button block w-full py-3 leading-4 text-center rounded-full"
                data-test="visit-school-button">
                  ${props.button}
              </button>
            </div>
          </div>
        `;
      })}
    </div>
    ${
      props.hideMoreButton
        ? ''
        : html`
        <button
          class="el-button button block mx-auto mt-6 mb-4 w-56 py-3 leading-4 text-center rounded-full"
          data-test="view-more-button"
          @click=${() => props.handleMoreClick()}
        >
          ${props.morebutton}
        </button>
      `
    }
  `;
};
