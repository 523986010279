import { html, TemplateResult } from 'lit-element';

export default (props): TemplateResult => {
  return html`
    ${
      props.hideDisclosure
        ? ''
        : html`
          <sonic-tooltip class="mb-4 text-xs text-center" position="right" label="${
            window.HE ? 'Learn More About Our Partners' : 'Advertisement'
          }">
            <p>
              ${window.location.hostname}
              is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.
            </p>
          </sonic-tooltip>
          `
    }
    <slot></slot>
    <div class="flex flex-col">
      ${props.mobius.response.map(({ product, metadata }, index) => {
        const { program, school, destination } = product;
        const position = index + 1;
        return html`
          <div
            class="card rounded border flex flex-col md:flex-row mb-4 bg-white shadow-lg ${
              props.clickableCards ? 'cursor-pointer' : ''
            }"
            data-editorial-listing=${program.id}
            @click=${
              props.clickableCards
                ? (e) => props.handleClick(e, product, metadata, position)
                : undefined
            }
          >
            <div class="relative border-b md:border-b-0 md:border-r flex flex-1 items-center p-5">
              <div>
                <a
                  @click=${(e) =>
                    props.handleClick(e, product, metadata, position)}
                  href="${destination.url}"
                  class="school font-bold block text-base md:text-2xl mb-1 leading-normal"
                  data-test="school-link"
                  rel="sponsored">
                    ${school.name}
                </a>
                <p class="location text-sm leading-relaxed">${
                  school.city && school.state
                    ? `${school.city}, ${school.state} & `
                    : ''
                }Online</p>
              </div>
            </div>
            <div class="px-4 pb-5 pt-4 flex flex-col flex-shrink-0 items-center justify-center md:w-72">
              <img
                class="mb-4"
                src="https://simple-storage-server.highereducation.com/${
                  school.slug
                }.png?w=150&h=42"
                alt="${school.name}"
                width="140"
                height="42"
                loading="lazy"
              />
              <button
                @click=${(e) =>
                  props.handleClick(e, product, metadata, position)}
                class="el-button button block w-full py-3 leading-4 text-center text-sm"
                data-test="visit-school-button">
                  ${props.button}
              </button>
            </div>
          </div>
        `;
      })}
    </div>
  `;
};
