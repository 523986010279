import { html, TemplateResult } from 'lit-element';

export default (props): TemplateResult => {
  return html`
    ${
      props.hideDisclosure
        ? ''
        : html`
          <sonic-tooltip class="mb-4 block text-xs text-center" label="${
            window.HE ? 'Learn More About Our Partners' : 'Advertisement'
          }">
            <p>
              ${window.location.hostname}
              is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.
            </p>
          </sonic-tooltip>
          `
    }
    <slot></slot>
    <div class="flex flex-row flex-wrap md:flex-nowrap gap-6 mx-auto w-max max-w-full justify-center">
      ${props.mobius.response.map(({ product, metadata }, index) => {
        const { program, school, destination } = product;
        const position = index + 1;
        const snippet =
          program.snippet ||
          program.description ||
          school.snippet ||
          school.description;
        return html`
          <div
            class="card rounded-md border flex flex-col md:flex-1 bg-white ${
              props.clickableCards ? 'cursor-pointer' : ''
            }"
            data-editorial-listing=${program.id}
            @click=${
              props.clickableCards
                ? (e) => props.handleClick(e, product, metadata, position)
                : undefined
            }
          >
            <div class="relative border-b flex-1">
              <div class="px-4 py-6">
                <img
                  class="mb-6 mx-auto"
                  src="https://simple-storage-server.highereducation.com/${
                    school.slug
                  }.png?w=160&h=72"
                  alt="${school.name}"
                  width="160"
                  height="72"
                  loading="lazy"
                />
                <span class="program text-base font-semibold leading-snug block mb-1">
                  ${program.name}
                </span>
                <a
                  @click=${(e) =>
                    props.handleClick(e, product, metadata, position)}
                  href="${destination.url}"
                  class="school font-bold block text-lg"
                  data-test="school-link"
                  rel="sponsored">
                    ${school.name}
                  </a>
                <p class="text-sm mt-4 leading-normal">${snippet}</p>
              </div>
            </div>
            <div class="p-5 flex flex-col flex-shrink-0 items-center justify-center">

              <button
                @click=${(e) =>
                  props.handleClick(e, product, metadata, position)}
                class="el-button card-button button block py-3 px-5 leading-4 text-center text-base rounded-full"
                data-test="visit-school-button">
                  ${props.button}
              </button>
            </div>
          </div>
        `;
      })}
    </div>
    ${
      props.hideMoreButton
        ? ''
        : html`
        <button
          class="el-button view-more-button button block mx-auto mt-8 mb-4 p-3 leading-4 text-center text-sm rounded-full"
          data-test="view-more-button"
          @click=${() => props.handleMoreClick()}
        >
          ${props.morebutton}
        </button>
      `
    }
  `;
};
