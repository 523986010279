import { html, TemplateResult } from 'lit-element';

export default (props): TemplateResult => {
  
  return html`
  <div class="max-w-5xl mx-auto">
    ${
      props.hideDisclosure
        ? ''
        : html`
          <sonic-tooltip class="mb-4 block text-xs text-center" label="${
            window.HE ? 'Learn More About Our Partners' : 'Advertisement'
          }">
            <p>
              ${window.location.hostname}
              is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.
            </p>
          </sonic-tooltip>
          `
    }
    <slot></slot>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6 justify-center">
      ${props.mobius.response.map(({ product, metadata }, index) => {
        const { program, school, destination } = product;
        const position = index + 1;
        const snippet =
          program.snippet ||
          school.snippet ||
          program.description ||
          school.description;
        const location =
          school.city && school.state
            ? `${school.city}, ${school.state}`
            : null;
        return html`
          <div
            class="card shadow-xl rounded-md border flex flex-col md:flex-1 bg-white ${
              props.clickableCards ? 'cursor-pointer' : ''
            }"
            data-editorial-listing=${program.id}
            @click=${
              props.clickableCards
                ? (e) => props.handleClick(e, product, metadata, position)
                : undefined
            }
          >
            <div class="relative flex-1">
              <div class="px-4 py-6">

                <span class="program text-xs font-semibold leading-snug block mb-1">
                  ${program.name}
                </span>
                <a
                  @click=${(e) =>
                    props.handleClick(e, product, metadata, position)}
                  href="${destination.url}"
                  class="school font-bold block text-lg"
                  data-test="school-link"
                  rel="sponsored">
                    ${school.name}
                  </a>
                ${
                  location
                    ? html`<span class="block text-xs text-gray-400">${location}</span>`
                    : ''
                }
                <p class="snippet text-sm mt-4 leading-normal">${snippet}</p>
              </div>
            </div>
            <div class="grid grid-cols-9 items-start justify-center gap-x-5 mx-5">
            <div class="col-span-4 h-20 bg-white py-3 -mb-10 shadow-xl drop-shadow-xl rounded-md">
              <img
                class="bg-white mx-auto"
                src="https://simple-storage-server.highereducation.com/${
                  school.slug
                }.png?w=95&h=58"
                alt="${school.name}"
            />
            </div>
              <button
                @click=${(e) =>
                  props.handleClick(e, product, metadata, position)}
                class="col-span-5 el-button card-button button block leading-4 mb-5 text-center text-xs font-bold rounded-md"
                data-test="visit-school-button">
                  ${props.button}
                  <sonic-icon icon="external-link" color="#FFF" size="x-small"></sonic-icon>
              </button>
            </div>
          </div>
        `;
      })}
    </div>
    ${
      props.hideMoreButton
        ? ''
        : html`
        <button
          class="el-button view-more-button button block mx-auto p-3 leading-4 text-center text-sm rounded-full"
          data-test="view-more-button"
          @click=${() => props.handleMoreClick()}
        >
          ${props.morebutton}
        </button>
      `
    }
    </div>
  `;
};
